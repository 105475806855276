import request from "@/utils/request";



export  function save(data){
  return request({
    method: "post",
    url: '/system/resource/save',
    data,
  });
}
export  function del(data){
  return request({
    method: "post",
    url: '/system/resource/delete',
    data,
  });
}
export  function list(data){
  return request({
    method: "post",
    url: '/system/resource/getList',
    data,
  });
}
export  function detail(data){
  return request({
    method: "get",
    url: '/system/resource/detail',
    params:data,
  });
}


export  function updateMore(data){
  return request({
    method: "post",
    url: '/system/resource/updateMore',
    data,
  });
}



