<template>
  <span>
    <el-button size="mini" type="primary" @click="start(id)">{{id ? $t('button.edit') : $t('button.add')}}</el-button>
    <el-dialog
      :title="id ? $t('button.edit') : $t('button.add')"
      :visible.sync="dialogVisible"
      width="400px">
      <div v-loading="loading">
        <el-form
          ref="form"
          :rules="rules"
          :model="role"
          label-width="auto"
          size="medium">
          <el-form-item :label="$t('role.name')" prop="name">
            <el-input v-model="role.name" :placeholder="$ts('placeholder.input', 'role.name')"/>
          </el-form-item>
          <el-form-item :label="$t('role.description')" prop="description">
            <el-input v-model="role.description" :placeholder="$ts('placeholder.input', 'role.description')"/>
          </el-form-item>
          <el-form-item :label="$t('role.sort')" prop="sort">
            <el-input v-model.number="role.sort" :placeholder="$ts('placeholder.input', 'role.sort')"/>
          </el-form-item>
        </el-form>
      </div>
      <template slot="footer">
        <el-button size="medium" type="primary" @click="onSubmit" :loading="setIng">{{$t('button.confirm')}}</el-button>
        <el-button size="medium" @click="dialogVisible = false">{{$t('button.cancel')}}</el-button>
      </template>
    </el-dialog>
  </span>
</template>
<script>
import {detail, save} from '@/api/role'
import {showSuccessMsg} from "@/utils/func";

export default {
  name: "SaveRoleBtn",
  
  props: {
    id: {
      type: [String, Number],
      default: ''
    }
  },
  
  data() {
    return {
      dialogVisible: false,
      loading: false,
      role: {},
      rules: {
        name: [
          {required: true, message: this.$ts('placeholder.input', 'role.name'), trigger: 'blur'},
        ],
        description: [
          {required: true, message: this.$ts('placeholder.input', 'role.description'), trigger: 'blur'},
        ],
        sort: [
          {required: true, message: this.$ts('placeholder.input', 'role.sort'), trigger: 'blur'},
        ]
      },
      
      setIng: false
    }
  },
  
  methods: {
    start(id) {
      this.dialogVisible = true
      this.loading = false
      this.role = {}
      this.setIng = false
      if (id) {
        this.getInfo(id)
      }
    },
    
    getInfo(id) {
      this.loading = true
      detail({id}).then(res => {
        this.role = res.data
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 500)
      })
    },
    
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.setIng = true
          let data = JSON.parse(JSON.stringify(this.role))
          save(data).then(() => {
            this.dialogVisible = false
            showSuccessMsg(this.$t('message.title'), (this.role.id ? this.$t('message.editSuccess') : this.$t('message.addSuccess')))
            this.$emit('xzdListPageReload', !this.role.id)
          }).finally(() => {
            setTimeout(() => {
              this.setIng = false
            }, 500)
          })
        }
      })
    }
  }
}
</script>
<style scoped>
</style>