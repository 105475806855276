import request from "@/utils/request";

export function save(data) {
  return request({
    method: "post",
    url: '/system/role/save',
    data,
  });
}

export function del(data) {
  return request({
    method: "post",
    url: '/system/role/delete',
    data,
  });
}

export function list(data) {
  return request({
    method: "post",
    url: '/system/role/getList',
    data,
  });
}

export function detail(data) {
  return request({
    method: "get",
    url: '/system/role/detail',
    params: data,
  });
}

export function getResourceIds(data) {
  return request({
    method: "get",
    url: '/system/role/getResourceIds',
    params: data,
  });
}

export function saveResources(data) {
  return request({
    method: "post",
    url: '/system/role/saveResources',
    data,
  });
}
